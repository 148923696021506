import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import styles from './blog-list.module.css'
import Pager from "../components/pager"

const BlogList = ({ data, location, pageContext }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMdx.edges
  const { currentPage, numPages } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All About Lottery" />
      {posts.map(({ node }, i) => {
        const title = node.frontmatter.title || node.fields.slug
        const featuredImageFixed = node.frontmatter.image.childImageSharp.fixed
        return (<>
          <article key={node.fields.slug} className={styles.article}>
            <header className={styles.titleHolder}>
              <h2 className={styles.title}>
                <Link className={styles.titleLink} to={node.fields.slug}>
                  {title}
                </Link>
              </h2>
            </header>
            <div className={styles.imageHolder}>
              <Link className={styles.imageLink} to={node.fields.slug}>
                <Img fixed={featuredImageFixed} />
              </Link>
            </div>
            <div className={styles.lead}>
              <section>
                {node.frontmatter.description || node.excerpt}
                <Link className={styles.button} to={node.fields.slug}>
                  VIEW POST
                </Link>
              </section>
            </div>
          </article>
        </>)
      })}
      <Pager currentPage={currentPage} numPages={numPages}/>
      <br/>
    </Layout>
  )
}

export default BlogList

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!){
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            image {
              childImageSharp {
                fixed(width: 300, height: 225) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
            description
          }
        }
      }
    }
  }
`
